import React from "react";
import Nav from "./nav";

const Header = ({ cats }) => {
  return (
    <header>
      <div className="bg-graymachete text-whitemachete">
        <div className="container mx-auto py-2">
          <Nav cats={cats} />
        </div>
      </div>
    </header>
  );
};

export default Header;
