import { Link } from "gatsby";
import React from "react";

export default function Footer() {
  let date = new Date();
  const year = date.getFullYear();
  return (
    <footer>
      <div className="py-8 mx-auto max-w-7xl">
        <div className="grid md:grid-cols-3 grid-cols-1 md:gap-4">
          <div className="md:py-4 text-center">
            <h4 className="font-header font-bold text-lg text-gray-700">
              Связаться
            </h4>
            <div className="py-4">+7 (929) 444‒26‒26</div>
          </div>
          <div className="md:py-4 text-center border-l border-r">
            <h4 className="font-header font-bold text-lg text-gray-700">
              Написать нам
            </h4>
            <div className="py-4">info@machete.store</div>
          </div>
          <div className="md:py-4 text-center">
            <h4 className="font-header font-bold text-lg text-gray-700">
              Задать вопрос
            </h4>
            <div className="py-4">Задайте ваш вопрос онлайн </div>
          </div>
        </div>
      </div>
      <div className="pb-6 px-4 md:px-0 md:pt-16 pt-8 bg-main">
        <div className="container mx-auto">
          <div className="grid md:grid-cols-4 grid-cols-1 md:gap-4">
            <div className="md:space-y-4 flex flex-col text-gray-400">
              <span>Принимаем к оплате</span>
            </div>
            <div className="block md:text-center text-gray-300 col-span-2">
              <div>
                Интернет-магазин ножей{" "}
                <span className="text-redmachete">Machete</span>
              </div>
              <div className="py-4">г. Краснодар, ул. Новороссийская, 3/12</div>
              <div className="text-sm text-gray-500 py-4">
                Работаем ежедневно с 10<sup>00</sup> до 20<sup>00</sup>
              </div>
              <div className="md:py-6 text-sm underline md:flex md:justify-between text-gray-500">
                <div>О нас</div>
                <Link to="/delivery">Оплата и доставка</Link>
                <div>Условия возврата</div>
                <div>Гарантия</div>
                <div>Политика конфиденциальности</div>
              </div>
            </div>
            <div className="block relative md:text-right py-4 md:py-0">
              <span className="text-gray-500">Мы в соцсетях</span>
              <span className="md:absolute md:inset-y-10 right-0 flex space-x-6">
                <a href="https://www.instagram.com/machete_095/">
                  <img
                    src="https://cdn4.iconfinder.com/data/icons/viiva-social-media/32/instagram-512.png"
                    className="h-10"
                    alt="Machete интернет магазин"
                  />
                </a>
                <img
                  src="https://cdn4.iconfinder.com/data/icons/viiva-social-media/32/facebook-1024.png"
                  className="h-10"
                  alt="Machete интернет магазин"
                />
              </span>
            </div>
          </div>
          <div className="text-center pt-10 text-gray-500 text-xs border-t border-opacity-5">
            &copy; 2022 - {year} Все права защищены. Ножевой магазин Machete
          </div>
        </div>
      </div>
    </footer>
  );
}
